import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
} from "reactstrap";
import { db, firebase } from "../../../firebase";
import "react-quill/dist/quill.snow.css";
import DropdownComponent from "../../forms/DropdownComponent";

import TextInput from "../../forms/TextInput";
import DateInput from "../../forms/DateInput";
import RichEditor from "../../forms/RichEditor";
import NumberInput from "../../forms/NumberImput";
import BooleanSwitchInput from "../../forms/BooleanSwitchInput";

function Add({ refreshList, addNotification }) {
  const [role, setRole] = useState("");
  const [company, setCompany] = useState("");
  const [type, setType] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isPresent, setIsPresent] = useState("");
  const [priority, setPriority] = useState("");
  const [role2, setRole2] = useState("");
  const [location2, setLocation2] = useState("");
  const [description2, setDescription2] = useState("");
  const [startDate2, setStartDate2] = useState("");
  const [endDate2, setEndDate2] = useState("");
  const [isPresent2, setIsPresent2] = useState("");
  const [role3, setRole3] = useState("");
  const [location3, setLocation3] = useState("");
  const [description3, setDescription3] = useState("");
  const [startDate3, setStartDate3] = useState("");
  const [endDate3, setEndDate3] = useState("");
  const [isPresent3, setIsPresent3] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownValues, setDropdownValues] = useState({
    status: [],
  });

  const [selectedStatus, setSelectedStatus] = useState([]);
  const [activeTab, setActiveTab] = useState("1");

  const firestoreCollection = "experience";

  useEffect(() => {
    let isMounted = true;

    const fetchDropdownValues = async () => {
      try {
        const doc = await db
          .collection("dropdowns")
          .doc(firestoreCollection)
          .get();
        if (doc.exists && isMounted) {
          setDropdownValues(doc.data());
        }
      } catch (error) {
        console.error("Error fetching dropdown values:", error);
        addNotification(
          "Error fetching dropdown values. Please refresh the page.",
          "danger"
        );
      }
    };
    fetchDropdownValues();
    return () => {
      isMounted = false;
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const newItem = {
        role,
        company,
        type,
        description,
        startDate,
        endDate,
        isPresent,
        location,
        role2,
        description2,
        startDate2,
        endDate2,
        isPresent2,
        location2,
        role3,
        description3,
        startDate3,
        endDate3,
        isPresent3,
        location3,
        status: selectedStatus,
        priority,
        isOn: false,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
      };

      const newItemRef = await db.collection(firestoreCollection).add(newItem);
      if (refreshList) {
        refreshList();
      }
      addNotification("New Item added successfully", "success");
      setCompany("");
      setRole("");
      setType("");
      setDescription("");
      setStartDate("");
      setEndDate("");
      setStartDate("");
      setIsPresent("");
      setLocation("");
      setRole2("");
      setDescription2("");
      setStartDate2("");
      setEndDate2("");
      setStartDate2("");
      setIsPresent2("");
      setLocation2("");
      setRole3("");
      setDescription3("");
      setStartDate3("");
      setEndDate3("");
      setStartDate3("");
      setIsPresent3("");
      setLocation3("");
      return newItemRef.id;
    } catch (error) {
      console.error("Error during form submission:", error);
      addNotification(
        "Error during form submission. Please try again.",
        "danger"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Form className="label tabs" onSubmit={handleSubmit}>
      <Nav tabs justified>
        <NavItem>
          <NavLink
            className={{ active: activeTab === "1" }}
            onClick={() => {
              toggle("1");
            }}
          >
            General
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={{ active: activeTab === "2" }}
            onClick={() => {
              toggle("2");
            }}
          >
            Additional Role
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={{ active: activeTab === "3" }}
            onClick={() => {
              toggle("3");
            }}
          >
            Additional Role 2
          </NavLink>
        </NavItem>
      </Nav>
      <hr />
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row className="mt-2">
            <Col>
              <TextInput
                label="Company"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                placeholder="e.g RASITGR Games"
              />
              <TextInput
                label="Location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                placeholder="e.g Berlin, GER"
              />
              <TextInput
                label="Role"
                value={role}
                onChange={(e) => setRole(e.target.value)}
                placeholder="e.g Game Developer"
              />
              <DateInput
                label="Start Date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <DateInput
                label="End Date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
              <BooleanSwitchInput
                label="Present?"
                value={isPresent}
                onChange={setIsPresent}
              />
              <TextInput
                label="Type"
                value={type}
                onChange={(e) => setType(e.target.value)}
                placeholder="e.g Remote, On Site"
              />
              <NumberInput
                label="Priority"
                value={priority}
                onChange={(e) => setPriority(e.target.value)}
                placeholder="Priority"
              />
              <RichEditor
                label="Description"
                value={description}
                onChange={setDescription}
              />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <TextInput
            label="Role"
            value={role2}
            onChange={(e) => setRole2(e.target.value)}
            placeholder="e.g Game Developer"
          />
          <TextInput
            label="Location"
            value={location2}
            onChange={(e) => setLocation2(e.target.value)}
            placeholder="e.g Berlin, GER"
          />
          <DateInput
            label="Start Date"
            value={startDate2}
            onChange={(e) => setStartDate2(e.target.value)}
          />
          <DateInput
            label="End Date"
            value={endDate2}
            onChange={(e) => setEndDate2(e.target.value)}
          />
          <BooleanSwitchInput
            label="Present?"
            value={isPresent2}
            onChange={setIsPresent2}
          />
          <RichEditor
            label="Description"
            value={description2}
            onChange={setDescription2}
          />
        </TabPane>
        <TabPane tabId="3">
          <TextInput
            label="Role"
            value={role3}
            onChange={(e) => setRole3(e.target.value)}
            placeholder="e.g Game Developer"
          />
          <TextInput
            label="Location"
            value={location3}
            onChange={(e) => setLocation3(e.target.value)}
            placeholder="e.g Berlin, GER"
          />
          <DateInput
            label="Start Date"
            value={startDate3}
            onChange={(e) => setStartDate3(e.target.value)}
          />
          <DateInput
            label="End Date"
            value={endDate3}
            onChange={(e) => setEndDate3(e.target.value)}
          />
          <BooleanSwitchInput
            label="Present?"
            value={isPresent3}
            onChange={setIsPresent3}
          />

          <RichEditor
            label="Description"
            value={description3}
            onChange={setDescription3}
          />
        </TabPane>
      </TabContent>
      <hr />
      <Button
        className="mt-1 primary"
        // color="primary"
        type="submit"
      >
        {isLoading ? <Spinner size="sm" color="light" /> : "Add"}
      </Button>
    </Form>
  );
}

export default Add;
