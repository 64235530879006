import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Row,
  Col,
} from "reactstrap";
import { db, firebase } from "../../../firebase";
import DropdownComponent from "../../forms/DropdownComponent";
import TextInput from "../../forms/TextInput";
import DateInput from "../../forms/DateInput";
import RichEditor from "../../forms/RichEditor";
import NumberInput from "../../forms/NumberImput";
import BooleanSwitchInput from "../../forms/BooleanSwitchInput";

function Update({ itemData, refreshList, addNotification }) {
  const [location, setLocation] = useState(itemData.location);
  const [university, setUniversity] = useState(itemData.university);
  const [department, setDepartment] = useState(itemData.department);
  const [degree, setDegree] = useState(itemData.degree);
  const [description, setDescription] = useState(itemData.description);
  const [priority, setPriority] = useState(itemData.priority);
  const [startDate, setStartDate] = useState(itemData.startDate);
  const [endDate, setEndDate] = useState(itemData.endDate);
  const [isPresent, setIsPresent] = useState(itemData.isPresent);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(itemData.status);
  const [dropdownValues, setDropdownValues] = useState({
    status: [],
  });
  const [activeTab, setActiveTab] = useState("1");

  const firestoreCollection = "education";

  useEffect(() => {
    let isMounted = true;

    const fetchDropdownValues = async () => {
      try {
        const doc = await db
          .collection("dropdowns")
          .doc(firestoreCollection)
          .get();
        if (doc.exists && isMounted) {
          setDropdownValues(doc.data());
        }
      } catch (error) {
        console.error("Error fetching dropdown values:", error);
        addNotification(
          "Error fetching dropdown values. Please refresh the page.",
          "danger"
        );
      }
    };

    fetchDropdownValues();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const updatedItem = {
        degree,
        department,
        university,
        location,
        priority,
        description,
        startDate,
        endDate,
        isPresent,
        status: selectedStatus,
        isOn: itemData.isOn,
        createdAt: itemData.createdAt,
        modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
      };

      await db
        .collection(firestoreCollection)
        .doc(itemData.id)
        .update(updatedItem);

      if (refreshList) {
        refreshList();
      }
      addNotification("Item updated successfully", "success");
    } catch (error) {
      console.error("Error during form update:", error);
      addNotification("Error during form update. Please try again.", "danger");
    } finally {
      setIsLoading(false);
    }
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Form className="tabs" onSubmit={handleSubmit}>
      <Nav tabs justified>
        <NavItem>
          <NavLink
            className={{ active: activeTab === "1" }}
            onClick={() => {
              toggle("1");
            }}
          >
            General
          </NavLink>
        </NavItem>
      </Nav>
      <hr />
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row className="mt-2">
            <Col>
              <TextInput
                label="Degree"
                value={degree}
                onChange={(e) => setDegree(e.target.value)}
                placeholder="Name"
              />
              <TextInput
                label="Department"
                value={department}
                onChange={(e) => setDepartment(e.target.value)}
                placeholder="Clients (comma separated, e.g. Developer, Designer)"
              />
              <DateInput
                label="Start Date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <DateInput
                label="End Date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
              <BooleanSwitchInput
                label="Present?"
                value={isPresent}
                onChange={setIsPresent}
              />
              <TextInput
                label="University"
                value={university}
                onChange={(e) => setUniversity(e.target.value)}
                placeholder="Project Link"
              />
              <TextInput
                label="Location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                placeholder="Demo reel or Youtube links"
              />
              <DropdownComponent
                label="Status"
                items={dropdownValues.status}
                selectedItems={selectedStatus}
                setSelectedItems={setSelectedStatus}
              />
              <NumberInput
                label="Priority"
                value={priority}
                onChange={(e) => setPriority(e.target.value)}
                placeholder="Priority"
              />
              <RichEditor
                label="Description"
                value={description}
                onChange={setDescription}
              />
            </Col>
          </Row>
        </TabPane>
      </TabContent>
      <hr />
      <Button
        className="mt-3 primary"
        // color="primary"
        type="submit"
      >
        {isLoading ? <Spinner size="sm" color="light" /> : "Update"}
      </Button>
    </Form>
  );
}

export default Update;
