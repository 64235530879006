import React, { useState } from "react";
import {
  Button,
  Form,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Row,
  Col,
  Input,
} from "reactstrap";
import { db, firebase } from "../../../firebase";
import NumberInput from '../../../components/forms/NumberImput';

function Update({ itemData, refreshList, addNotification }) {
  const [groupTitle, setGroupTitle] = useState(itemData.title);
  const [keywords, setKeywords] = useState(itemData.keywords || []);
  const [newKeyword, setNewKeyword] = useState("");
  const [priority, setPriority] = useState(itemData.priority || 0); // Load priority
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("1");

  const firestoreCollection = "skills";

  const handleAddKeyword = () => {
    if (newKeyword.trim() && !keywords.includes(newKeyword.trim())) {
      setKeywords((prevKeywords) => [...prevKeywords, newKeyword.trim()]);
      setNewKeyword("");
    } else {
      addNotification("Keyword is empty or already added.", "warning");
    }
  };

  const handleRemoveKeyword = (keyword) => {
    setKeywords((prevKeywords) => prevKeywords.filter((kw) => kw !== keyword));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const updatedGroup = {
        title: groupTitle.trim(),
        keywords,
        priority: parseInt(priority, 10) || 0, // Save priority as an integer
        isOn: itemData.isOn,
        createdAt: itemData.createdAt,
        modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
      };

      await db.collection(firestoreCollection).doc(itemData.id).update(updatedGroup);

      if (refreshList) {
        refreshList();
      }
      addNotification("Skill group updated successfully", "success");
    } catch (error) {
      console.error("Error during form update:", error);
      addNotification("Error during form update. Please try again.", "danger");
    } finally {
      setIsLoading(false);
    }
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Form className="tabs" onSubmit={handleSubmit}>
      <Nav tabs justified>
        <NavItem>
          <NavLink
            className={{ active: activeTab === "1" }}
            onClick={() => toggle("1")}
          >
            General
          </NavLink>
        </NavItem>
      </Nav>
      <hr />
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row className="mt-2">
            <Col>
              <div className="form-group">
                <label>Skill Group Title</label>
                <Input
                  type="text"
                  value={groupTitle}
                  onChange={(e) => setGroupTitle(e.target.value)}
                  placeholder="e.g., Programming & Tools"
                />
              </div>

              <NumberInput
                label="Priority"
                value={priority}
                onChange={(e) => setPriority(e.target.value)}
                placeholder="e.g., 1"
              />

              <div className="form-group">
                <label>Keywords</label>
                <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                  <Input
                    type="text"
                    value={newKeyword}
                    onChange={(e) => setNewKeyword(e.target.value)}
                    placeholder="Add a keyword (e.g., Unity3D)"
                    style={{ marginRight: "10px" }}
                  />
                  <Button className="primary" type="button" onClick={handleAddKeyword}>
                    +
                  </Button>
                </div>
                <div>
                  {keywords.map((keyword, index) => (
                    <Button
                      key={index}
                      className="primary m-1"
                      onClick={() => handleRemoveKeyword(keyword)}
                    >
                      {keyword}
                      <span style={{ marginLeft: "10px", cursor: "pointer" }}>X</span>
                    </Button>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
      <hr />
      <Button className="mt-3 primary" type="submit">
        {isLoading ? <Spinner size="sm" color="light" /> : "Update Skill Group"}
      </Button>
    </Form>
  );
}

export default Update;
