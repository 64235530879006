import React, { useState } from "react";
import {
  Button,
  Form,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
  Input,
} from "reactstrap";
import { db, firebase } from "../../../firebase";
import NumberInput from '../../../components/forms/NumberImput';

function Add({ refreshList, addNotification }) {
  const [groupTitle, setGroupTitle] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [newKeyword, setNewKeyword] = useState("");
  const [priority, setPriority] = useState(0); // Default priority value

  const [activeTab, setActiveTab] = useState("1");
  const firestoreCollection = "skills";

  const handleAddKeyword = () => {
    if (newKeyword.trim() && !keywords.includes(newKeyword.trim())) {
      setKeywords((prevKeywords) => [...prevKeywords, newKeyword.trim()]);
      setNewKeyword("");
    } else {
      addNotification("Keyword is empty or already added.", "warning");
    }
  };

  const handleRemoveKeyword = (keyword) => {
    setKeywords((prevKeywords) => prevKeywords.filter((kw) => kw !== keyword));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!groupTitle.trim()) {
      addNotification("Skill Group Title is required.", "danger");
      return;
    }

    try {
      const newGroup = {
        title: groupTitle.trim(),
        keywords,
        priority: parseInt(priority, 10) || 0, // Save priority as an integer
        isOn: false, // Default to off
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
      };

      const newGroupRef = await db.collection(firestoreCollection).add(newGroup);
      if (refreshList) {
        refreshList();
      }
      setGroupTitle("");
      setKeywords([]);
      setPriority(0);
      addNotification("Skill group added successfully.", "success");

      return newGroupRef.id;
    } catch (error) {
      console.error("Error during form submission:", error);
      addNotification("Error adding skill group. Please try again.", "danger");
    }
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Form className="label tabs" onSubmit={handleSubmit}>
      <Nav tabs justified>
        <NavItem>
          <NavLink
            className={{ active: activeTab === "1" }}
            onClick={() => {
              toggle("1");
            }}
          >
            General
          </NavLink>
        </NavItem>
      </Nav>
      <hr />
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row className="mt-2">
            <Col>
              <div className="form-group">
                <label>Skill Group Title</label>
                <Input
                  type="text"
                  value={groupTitle}
                  onChange={(e) => setGroupTitle(e.target.value)}
                  placeholder="e.g., Programming & Tools"
                />
              </div>

              <NumberInput
                label="Priority"
                value={priority}
                onChange={(e) => setPriority(e.target.value)}
                placeholder="e.g., 1"
              />

              <div className="form-group">
                <label>Keywords</label>
                <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                  <Input
                    type="text"
                    value={newKeyword}
                    onChange={(e) => setNewKeyword(e.target.value)}
                    placeholder="Add a keyword (e.g., Unity3D)"
                    style={{ marginRight: "10px" }}
                  />
                  <Button className="primary" type="button" onClick={handleAddKeyword}>
                    +
                  </Button>
                </div>
                <div>
                  {keywords.map((keyword, index) => (
                    <Button
                      key={index}
                      className="primary m-1"
                      onClick={() => handleRemoveKeyword(keyword)}
                    >
                      {keyword}
                      <span style={{ marginLeft: "10px", cursor: "pointer" }}>X</span>
                    </Button>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
      <hr />
      <Button className="mt-1 primary" type="submit">
        Add Skill Group
      </Button>
    </Form>
  );
}

export default Add;
